import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"

import ReactMarkdown from "react-markdown"
import Moment from "react-moment"
import SEO from "../components/seo"


import Layout from "../components/layoutGeneric"
import MediaLeftList from "../components/listings/media-left-list"
import MediaTopList from "../components/listings/media-top-list"

export const query = graphql`  
  query ArticleQuery($slug: String!) {
    facebook:facebook {
    id
  }
    site: site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    article: strapiArticles(slug: { eq: $slug }) {
      strapiId
      slug
      title
      excerpt
      content
      createdAt
      category {
          name
        }
      banner {
        publicURL
        childImageSharp {
          original{
            height
            width
            src
          }
        }
      }
    }
    recentNews:allStrapiArticles(limit: 6, skip: 0, sort: {fields: createdAt, order: ASC}, filter: {status: {eq:"published"}, slug: {ne: ""}, 
          category: {slug: {eq: "howtos-and-guides"}}
          }) {
            edges {
              node {
                slug
                strapiId
                id
                excerpt
                createdAt
                title
                isFeatured
                category {
                  name
                }
                banner {
                  childImageSharp {
                    fixed(width: 200, height: 160, toFormat: JPG) {
                      srcSet
                      width
                      height
                      src
                      aspectRatio
                    }
                  }
                }
              }
            }
          }
          recentFeatures: allStrapiArticles(limit: 2, skip: 0, sort: {fields: createdAt,order: ASC}, filter: {status: {},
          # isFeatured:{eq:false},
           slug: {ne: ""}, category: {slug: {eq: "custom-motorcycle"}}}) {
            edges {
              node {
                slug
                strapiId
                id
                excerpt
                createdAt
                title
                isFeatured
                category {
                  name
                }
                banner {
                  childImageSharp {
                    fixed(width: 400, height: 250, toFormat: JPG) {
                      srcSet
                      width
                      height
                      src
                      aspectRatio
                    }
                  }
                }
              }
            }
          }
  }
`


const Article = ({ data }) => {
  const facebook = data.facebook
  const site = data.site.siteMetadata
  const article = data.article
  const recentNews = data.recentNews
  const recentFeatures = data.recentFeatures
  console.log(article)
  let image = article.banner.publicURL
    ? article.banner.childImageSharp.original
    : null
  // ----------------------
  // RUNTIME DATA FETCHING
  // ----------------------
  let path = `${site.siteUrl}/article/${article.slug}`
  let url = `https://api.sharedcount.com/v1.0/?url=${path}&apikey=1934f519a63e142e0d3c893e59cc37fe0172e98a`
  const [FBCount, setFBCount] = useState(0)
  useEffect(() => {
    // get data from GitHub api
    fetch(url)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        setFBCount(resultData.Facebook)
      }) // set data for the number of stars
  }, [])


  // FB.init({
  //   appId: "368375610781894",
  //   status: true,
  //   xfbml: true,
  //   version: 'v2.7' // or v2.6, v2.5, v2.4, v2.3
  // })
  // let ui = facebook.ui({
  //   method: 'share',
  //   href: 'https://developers.facebook.com/docs/'
  // }, function (response) {
  //   console.log('pindot')
  // });
  return (

    <Layout>

      <SEO
        title={article.title}
        description={article.content ? `${article.content.substr(0, 150)}...` : null}
        image={image}
        pathname={path}
      />

      <div
        className="uk-hidden@s uk-height-medium uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-background-top-center uk-light uk-padding-remove uk-margin-remove"
        data-src={image.src}
        data-srcset={image.src}
        data-uk-img
      >
      </div>

      <div className="uk-section">
        <div className="uk-container uk-container-small">
          <div
            // id="banner"
            className="uk-visible@s uk-height-large uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-background-top-center uk-light uk-padding-remove uk-margin-remove"
            data-src={image.src}
            data-srcset={image.src}
            data-uk-img
          >
          </div>
        </div>
        <div className="uk-container uk-container-small">
          <div className="uk-grid-divider uk-grid" uk-grid="">
            <div className="uk-first-column uk-width-2-3@m  uk-width-1-1@s">
              <br />
              <h2 className="uk-h2 uk-margin-small uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >
                {article.title}
              </h2>
              <div className="uk-text-meta uk-margin-small uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >
                By Admin | <Moment format="MMM Do YYYY">{article.createdAt}</Moment> - in <span className="uk-text-primary">{article.category.name}</span>
              </div>
              <hr />
              <a
                className="uk-icon-link uk-icon uk-button uk-button-primary uk-button-small"
                href={`https://facebook.com/sharer/sharer.php?u=` + path} target="_blank" rel="noopener noreferrer"
                k-icon="facebook"
              ><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="facebook"><path d="M11,10h2.6l0.4-3H11V5.3c0-0.9,0.2-1.5,1.5-1.5H14V1.1c-0.3,0-1-0.1-2.1-0.1C9.6,1,8,2.4,8,5v2H5.5v3H8v8h3V10z"></path></svg>Share</a>
               &nbsp;&nbsp;&nbsp;<span className="uk-text-emphasis">Shares: <span className="uk-text-primary">{FBCount.share_count}</span></span>
              &nbsp;&nbsp;&nbsp;<span className="uk-text-emphasis">Reactions: <span className="uk-text-primary">{FBCount.reaction_count}</span></span>
              &nbsp;&nbsp;&nbsp;<span className="uk-text-emphasis">Comments: <span className="uk-text-primary">{FBCount.comment_count}</span></span>
              <br />
              <br />
              <br />
              <div className="uk-panel">
                <ReactMarkdown source={article.content} />
                <br />
                <br />
                <a
                  className="uk-icon-link uk-icon uk-button uk-button-primary uk-button-small"
                  href={`https://facebook.com/sharer/sharer.php?u=` + path} target="_blank" rel="noopener noreferrer"
                  k-icon="facebook"
                >
                  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="facebook"><path d="M11,10h2.6l0.4-3H11V5.3c0-0.9,0.2-1.5,1.5-1.5H14V1.1c-0.3,0-1-0.1-2.1-0.1C9.6,1,8,2.4,8,5v2H5.5v3H8v8h3V10z"></path></svg>Share</a>
              </div>
            </div>
            <div className="uk-panel uk-width-1-3@m uk-width-1-1@s">
              <br />
              <MediaTopList
                data={recentFeatures}
                title="RECENT FEATURES"
                columns={1}
              />
              <MediaLeftList
                data={recentNews}
                title="HOW TOs & GUIDES"
              />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>

    </Layout >

  )
}

export default Article 